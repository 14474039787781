<template>
    <div class="ContractPage">
        <div class="tempContent">

            <div class="stakewait">
                <div class="coinInfomation">
                    <img :src="EvWatch" alt="" />
                    <div class="coinInfo">{{ $t("Wathch.watchonlywallet") }}</div>
                </div>

                <Divider style="background: rgba(142, 200, 243, 0.1)" />
                <!-- <div><img class="bStyle" :src="stylea" alt=""></div> -->
                <div class="observe_list">
                    <div v-for="(item, index) in list" :key="index">
                        <div class="observe_list_c" @click="onobserve(item)">
                            <p class="observe_list_c_l">
                                {{ item.address | addressconvert }}
                            </p>
                            <img v-if="observetype == item.address" class="observe_list_c_img"
                                src="../assets/img/icon02.png" alt="" />
                            <img v-else class="observe_list_c_img" src="../assets/img/icon01.png" alt="" />
                        </div>
                        <div class="StakeInfo" v-if="observetype == item.address">
                            <div class="StakeResult">
                                {{ honorLeave }}
                            </div>
                            <div class="StakeMoney">
                                ( {{ usersdata.teamCount }} / {{ usersdata.teamInvestAmount }} )
                            </div>
                        </div>
                    </div>
                </div>

                <div class="back"><button @click="$router.go(-1)">Back</button></div>
            </div>
        </div>
    </div>
</template>
  
<script>
import web3Utils from "@/utils/web3Utils.js";
import EvWatch from "@/assets/img/EvWatch.svg";
import matic from "@/assets/img/matic.svg";
import EvCon from "@/assets/img/EvCon.svg";
import stylea from "@/assets/img/stylea.png";
import honor from "@/assets/img/honor.svg";
import link from "@/assets/img/link.svg";
import contract from "@/plugins/contract.js";
import siteapi from "@/json/siteapi.json";
import daimni from "@/assets/img/daimini.svg";
export default {
    name: "EvWatchList",
    data() {
        return {
            matic,
            EvWatch,
            EvCon,
            stylea,
            WatchAddress: "",
            copySuccess: this.$t("Contract.Copylink"),
            copySuccessText: this.$t("Contract.CopylinkText"),
            observe: "",
            list: [],
            addresscode: "",
            honor: honor,
            daimni: daimni,
            link: link,
            honorLeave: "",
            usersdata: {
                teamCount: "",
                teamInvestAmount: 0.0,
            },
            observetype: "",
        };
    },
    async mounted() {
        // is Matic chain?
        if (typeof window.ethereum !== "undefined") {
            //  console.log("contented wallet");

            // anyc get
            window.ethereum
                .request({ method: "eth_chainId" })
                .then((chainId) => {
                    // is matic chain?
                    if (chainId === "0x89") {
                        //  console.log("current chain is matic");
                        // this.$router.push({path:'/EvStake'});
                    } else {
                        //  console.log("current chain is not matic");
                        this.$router.push({ path: "/" });
                        return;
                    }
                })
                .catch((error) => {
                    console.error("get network error:", error);
                });
        } else {
            console.log("no wallet");
        }

        const currentAddress = await web3Utils.connectWallet();
        //  console.log(currentAddress);
        this.addresscode = currentAddress;
        this.fungetItem();
        //this.$refs.myInput.focus();
    },
    filters: {
        addressconvert(value) {
            return value.slice(0, 10) + "……" + value.slice(-9);
        },
    },
    methods: {
        // user information
        async users(e) {
            let _decimals = 18;
            let ContractAddress;
            //  console.log("User information");
            ContractAddress = siteapi.aave_token.address;
            await contract
                .users(
                    e.address, //current address
                    ContractAddress, //call contract address
                    _decimals
                )
                .then((res) => {
                    //   console.log(res, "User information");
                    if (res.data.level == 0) {
                        this.honorLeave = this.$t("leavel.0");
                    } else {
                        this.honorLeave = this.$t("leavel." + res.data.level);
                    }

                    this.usersdata = res.data;
                    this.usersdata.teamInvestAmount = (
                        this.usersdata.teamInvestAmount * 1
                    ).toFixed(2);
                });
        },
        // select Watch Address
        onobserve(e) {
            // e.type=true
            this.users(e);
            //  console.log(e);
            //   console.log(this.list);
            this.observetype = e.address;
            //  console.log(this.observetype);

            //   window.localStorage.setItem('observeAddress',e.address)
            //   this.$router.push({path:'/'});
            //   location.reload();
        },
        fungetItem() {
            // console.log(this.addresscode);
            var data = window.localStorage.getItem("observeList");
            if (data) {
                var observeList = JSON.parse(data);
                // console.log(observeList);
                this.list = observeList.list;
                // if (this.addresscode == observeList.address) {
                //   this.list = observeList.list;
                // } else {
                //   window.localStorage.setItem("observeList", "");
                // }
            }
        },
        // add watch address
        addobserve() {
            if (!this.observe || this.observe.length !== 42 || !this.observe.startsWith('0x')) {
                this.$Notice.warning({
                    title: this.$t("Wathch.nullString"),
                });
                return;
            }

            if (this.observe == undefined || this.observe == "") {
                this.$Notice.warning({
                    title: this.$t("Wathch.nullString"),
                });
            } else {
                var observeList = window.localStorage.getItem("observeList");
                //   console.log(observeList);
                if (observeList) {
                    var result = JSON.parse(observeList); // jsonstr
                    // console.log(result);
                    var type = false;
                    result.list.map((val) => {
                        if (val.address == this.observe) {
                            type = true;
                        }
                    });
                    if (type) {
                        this.$Notice.warning({
                            title: this.$t("Wathch.addisExists"),
                        });
                    } else {
                        result.list.push({ address: this.observe });
                        // console.log(result);
                        let jsonString = JSON.stringify(result);
                        // console.log(jsonString);
                        window.localStorage.setItem("observeList", jsonString);
                        this.$Notice.success({
                            title: this.$t("Wathch.addok"),
                        });
                    }
                } else {
                    var json = {
                        address: this.addresscode,
                        list: [{ address: this.observe }],
                    };
                    let jsonString = JSON.stringify(json);
                    window.localStorage.setItem("observeList", jsonString);
                    this.$Notice.success({
                        title: this.$t("Wathch.addok"),
                    });
                }
                this.observe = "";
                this.fungetItem();
            }
        },
        onCopy() {
            this.success(false);
            // console.log('Text successfully copied');
        },
        error(nodesc) {
            this.$Notice.error({
                title: nodesc ? "" : this.$t("Wathch.fnOpen"),
                //desc: nodesc ? "" : this.$t("Wathch.fnOpen"),
            });
        },
        success(nodesc) {
            this.$Notice.success({
                title: nodesc ? "" : this.copySuccessText,
                //desc: nodesc ? "" : this.copySuccessText,
            });
        },
        warning(nodesc) {
            this.$Notice.warning({
                title: nodesc ? "" : this.$t("Wathch.nullString"),
                //desc: nodesc ? "" : this.$t("Wathch.nullString"),
            });
        },
        addWatch() {
            if (this.WatchAddress == "") {
                this.warning(false);
            } else {
                this.error(false);
            }
        },
    },
    computed: {
        shortenedAddress: {
            get() {
                if (this.invAddress === "") {
                    return "0x";
                } else {
                    return (
                        this.invAddress.slice(0, 8) + "……" + this.invAddress.slice(-10)
                    );
                }
            },
            set(newVal) {
                this.invAddress = newVal;
            },
        },
        linkAddress2: {
            get() {
                return `https://polygonscan.com/address/${this.invAddress}`;
            },
        },
    },
};
</script>
  
<style lang="less" scoped>
.ripple {
    position: relative;
    overflow: hidden;
}

.ripple:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-image: radial-gradient(circle, #666 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform .3s, opacity .5s;


}

.ripple:active:after {
    transform: scale(0, 0);
    opacity: .3;
    transition: 0s;
}

.ContractPage {
    .stakewait {
        margin-top: 10px;
        border-radius: 10px;
        box-shadow: 0px 0px 0px 0.4px rgb(80, 77, 77) inset;
        background-image: linear-gradient(133deg,
                rgba(7, 110, 150, 0.3),
                /* Adjust the last value (0.2) to set the opacity of the first color */
                rgba(132, 2, 72, 0.6));
        /* Adjust the last value (0.2) to set the opacity of the second color */
        box-sizing: border-box;
        width: 330px;
        color: white;
        padding: 40px 20px 20px 20px;

        .bStyle {
            width: 290px;
            height: 76px;
        }

        .StakeAuthor {
            justify-content: space-between;

            .StakeBtn2 {
                width: 100%;
                height: 50px;
                line-height: 50px;
                margin-top: 20px;
                border-radius: 10px;
                border: none;
                outline: none;
                cursor: pointer;
                font-size: 18px;
                color: rgb(2, 0, 0);
                font-size: 20px;
                background-image: linear-gradient(126deg,
                        rgba(142, 200, 243, 0.3),
                        /* Adjust the last value (0.2) to set the opacity of the first color */
                        rgba(250, 51, 158, 0.3));
            }



            .Author {
                width: 136px;
                height: 43px;
            }

            .Stakeing {
                width: 136px;
                height: 43px;
            }
        }

        .StakeInfo {
            display: flex;
            margin-bottom: 20px;
            padding: 0 0;

            .StakeResult {
                flex: 1;
                text-align: left;
                font-size: 14px;
                font-weight: 600;

                img {
                    width: 20px;
                    height: 20px;
                    position: relative;
                    top: 5px;
                    margin-right: 10px;
                }
            }

            .StakeMoney {
                text-align: right;
                font-size: 14px;
                font-weight: 600;

                img {
                    width: 20px;
                    height: 20px;
                    position: relative;
                    top: 5px;
                }

                .daimi {
                    margin-left: 5px;
                    width: 14px;
                    height: 12px;
                }
            }

            .StakeTeam {
                text-align: right;
                font-size: 14px;
                font-weight: 600;

                img {
                    width: 20px;
                    height: 20px;
                    position: relative;
                    top: 5px;
                    //margin-right: 10px;
                    margin-left: 10px;
                }

                .daimi {
                    margin-left: 5px;
                    width: 14px;
                    height: 12px;
                }
            }
        }

        .inputInfo {
            border: 2px solid #81c0f1;
            height: 53px;
            box-sizing: border-box;
            border-radius: 8px;
            margin: 10px 0;
            display: flex;

            .tbnStyle {
                height: 34px;
                position: relative;
                color: white;
                top: 8px;
                right: 16px;
                border: none;
                outline: none;
                font-size: 20px;
                border-radius: 12px;
                padding: 0 10px;
                cursor: pointer;
                background: url("../assets/img/Gradient.svg") no-repeat center center/cover;
            }
        }

        .ownwalletInfo {
            font-family: "Poppins", sans-serif;
            display: flex;

            .ownTips {
                font-size: 18px;
                font-weight: 600;
                flex: 1;
            }

            .ownMoney {
                font-size: 20px;

                img.daimi {
                    width: 14px;
                    height: 12px;
                    margin-left: 5px;
                }
            }
        }

        .maticlink {
            margin: 15px 0;
            display: flex;
            justify-content: center;

            .cLogo {
                width: 25px;
                height: 22px;
                margin-right: 10px;
            }

            .cName {
                font-size: 15px;
                font-family: "Poppins", sans-serif;
                font-weight: bold;
            }
        }

        .inputInfoOk {
            width: 100%;
            border: 2px solid #86c6f8;
            padding: 10px;
            box-sizing: border-box;
            border-radius: 15px;
            margin-bottom: 15px;
            text-align: center;
            font-size: 20px;

            .inputStyle {
                border: none;
                outline: none;
                flex: 1;
                height: 100%;
                width: 100%;
                font-size: 20px;
                padding: 0 10px;
                color: #e2e5e7;
                background-color: transparent;
                box-sizing: border-box;
                border-radius: 8px;
            }
        }

        .coinInfomation {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;

            .coinImg {
                width: 29px;
                height: 29px;

                .dailogo {
                    width: 29px;
                    height: 29px;
                }
            }

            .coinInfo {
                font-size: 18px;
                font-family: "Poppins", sans-serif;
                position: relative;
                top: 0px;
                font-weight: bold;
                margin-left: 10px;
            }

            .coinInfo2 {
                font-size: 28px;
                font-family: "Poppins", sans-serif;
                position: relative;
                top: 0px;
                font-weight: bold;

                img {
                    width: 20px;
                    height: 20px;
                    position: relative;
                    left: 5px;
                }
            }
        }
    }

    .tempContent {
        width: 330px;
        padding: 10px 0;
        margin: 0 auto;

        .head {
            width: 330px;
            padding: 10px 0;
            border-radius: 6px;
            margin: 0 auto;
            background: linear-gradient(to bottom,
                    #00050a 40%,
                    hsla(207, 4%, 48%, 0.4));
            display: flex;
            justify-content: center;

            .title {
                color: #fff;
                font-size: 18px;
                font-weight: 600;
                margin-left: 10px;
                position: relative;
                top: -4px;
                font-family: "Poppins", sans-serif;
            }
        }
    }
}

.observe_list {
    margin-top: 20px;
}

.back {
    button {
        width: 70px;
        height: 40px;
        line-height: 37px;
        // background: rgb(0, 0, 0);
        color: rgb(19, 1, 1);
        border-radius: 5px;
        font-size: 14px;
        font-weight: bolder;
        border: 1px solid #1a41ce;
        outline: none;
        cursor: pointer;
        background: url('../assets/img/Gradient.svg') no-repeat center center/cover;
    }
}

.observe_list_c {
    border: 1px solid #81c0f1;
    height: 53px;
    box-sizing: border-box;
    border-radius: 12px;
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    position: relative;
}

.observe_list_c_l {
    color: #ffffff;
    font-size: 17px;
    font-family: "Poppins", sans-serif;
}

.observe_list_c_img {
    width: 18px;
    height: 18px;
}
</style>
  