<template>
    <div class="SelectMain">
        <div class="GradientBorderContainer">
            <div class="PolygonNetwork">
                <div class="top"><img :src="EvMatic"></div>
                <div class="tips">
                    <div class="lf"><img :src="Warning"></div>
                    <div class="rf">
                        <p>{{ $t('network.tips') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EvMatic from '@/assets/img/EvMatic2.svg'
import Warning from '@/assets/img/Warning.svg'
export default {
    name: "SelectNetwork",
    data() {
        return {
            EvMatic: EvMatic,
            Warning: Warning
        }
    },
    components: {
    },
    mounted(){
        // 判断是否连接钱包
if (typeof window.ethereum !== 'undefined') {
  console.log("已连接钱包");

  // 异步获取网络信息
  window.ethereum
    .request({ method: 'eth_chainId' })
    .then((chainId) => {
      // 判断当前网络是否为Polygon链
      if (chainId === '0x89') {
        console.log("当前网络为Polygon链");
        this.$router.push({path:'/EvStake'});
      } else {
        console.log("当前网络不是Polygon链");
      }
    })
    .catch((error) => {
      console.error('获取网络信息失败:', error);
    });
} else {
  console.log("未连接钱包");
}
 
    }
};
</script>

<style lang="less" scoped>
@keyframes borderColorChange {

    0%,
    100% {
        background: linear-gradient(to bottom, #6EB2EE, #FA3199);
    }

    25% {
        background: linear-gradient(to bottom, #3498DB, #2ECC71);
    }

    50% {
        background: linear-gradient(to bottom, #FA3199, #6EB2EE);
    }

    75% {
        background: linear-gradient(to bottom, #F4D03F, #E74C3C);
    }
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-20px);
    }

    60% {
        transform: translateY(-10px);
    }
}

.SelectMain {
    width: 100vw;
    height: 100vh;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;

    .GradientBorderContainer {
        position: relative;
        width: 321px;
        height: 220px;
        border-radius: 28px;
        background: linear-gradient(to bottom, #6EB2EE, #FA3199);
        animation: borderColorChange 20s infinite;
        transition: background 5s ease-in-out;
        padding: 3px;
    }

    .PolygonNetwork {
        width: 100%;
        height: 100%;
        background-color: rgb(0, 0, 0);
        border-radius: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .top {
            display: flex;
            justify-content: center;

            img {
                width: 79px;
                height: 43px;
                animation: bounce 2s infinite;
            }
        }

        .tips {
            display: flex;
            margin: 0 auto;
            margin-top: 20px;

            .lf {
                margin: 0 10px;

                img {
                    width: 19px;
                    height: 19px;
                }
            }

            .rf {
                p {
                    color: #fff;
                    font-size: 14px;
                    line-height: 20px;
                    font-family: 'Space Grotesk', sans-serif;
                }
            }
        }
    }
}
</style>


