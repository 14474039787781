import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import i18n from "../utils/i18n";

import ContactNetwork from "@/views/ContactNetwork.vue";
import EvContract from "@/views/EvContract.vue";
import EvError from "@/views/EvError.vue";
import EvShare from "@/views/EvShare.vue";
import EvStake from "@/views/EvStake.vue";
import EvTeam from "@/views/EvTeam.vue";
import EvWatchOnlyWallet from "@/views/EvWatchOnlyWallet.vue";
import EvWatchOnlyWalletAdd from "@/views/EvWatchOnlyWalletAdd.vue";
import SelectNetwork from "@/views/SelectNetwork.vue";
import EvWithdraw from "@/views/EvWithdraw.vue";
import EvHome from "@/views/EvHome.vue";
import EvEnvironment from "@/views/EvEnvironment.vue";
import EvPrivate from "@/views/EvPrivate.vue";
import EvOther from "@/views/EvOther.vue";
import EvWatch from "@/views/EvWatch.vue";
import test from "@/views/test.vue";
import EvWatchList from "@/views/EvWatchList.vue";

Vue.use(VueRouter);
const router = new VueRouter({
  // mode: "history",
  routes: [
    { path: "/SelectNetwork", component: SelectNetwork },
    { path: "/", component: SelectNetwork },
    { path: "/EvError", component: EvError },
    { path: "/test", component: test },
    { path: "/EvEnvironment", component: EvEnvironment },
    { path: "/EvPrivate", component: EvPrivate },
    {
      path: "/subpage",
      component: EvOther,
      children: [
        { path: "/EvContract", component: EvContract },
        { path: "/EvWatch", component: EvWatch },
        { path: "/EvWatchList", component: EvWatchList },
      ],
    },
    {
      path: "/home",
      component: EvHome,
      meta: { requiresPolygon: true },
      children: [
        { path: "/EvShare", component: EvShare },
        { path: "/EvStake", component: EvStake },
        { path: "/staking", component: EvTeam },
        { path: "/EvTeam", component: EvTeam },
        { path: "/EvWatchOnlyWallet", component: EvWatchOnlyWallet },
        { path: "/EvWatchOnlyWalletAdd", component: EvWatchOnlyWalletAdd },
        { path: "/EvWithdraw", component: EvWithdraw },
        { path: "/ContactNetwork", component: ContactNetwork },
      ],
    },
  ],
});

// Check if on Polygon Network
// function isOnPolygonNetwork() {
//   if (typeof window.ethereum !== "undefined") {
//     return window.ethereum.chainId === "0x89";
//   }
//   return false;
// }

router.beforeEach((to, from, next) => {
  const language = store.state.language;
  i18n.locale = language;

  // Polygon network check for all routes
  // if (!isOnPolygonNetwork()) {
  //   next("/SelectNetwork");
  // } else {
  //   next();
  // }

  next();
});

export default router;
