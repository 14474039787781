import Web3 from "web3";

// DAI合约的通用ERC-20 ABI和地址
const DAI_ABI = [
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
];
const DAI_CONTRACT_ADDRESS = "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063";

const web3 = new Web3(window.ethereum || "https://polygon-rpc.com");

async function connectWallet() {
  try {
    await window.ethereum.enable();
    const accounts = await web3.eth.getAccounts();
    return accounts.length > 0 ? accounts[0] : null;
  } catch (error) {
    console.error("Error connecting wallet:", error);
    return null;
  }
}

async function getDaiBalance(address) {
  try {
    const daiContract = new web3.eth.Contract(DAI_ABI, DAI_CONTRACT_ADDRESS);
    const balanceWei = await daiContract.methods.balanceOf(address).call();
    return web3.utils.fromWei(balanceWei, "ether");
  } catch (error) {
    console.error("Error fetching DAI balance:", error);
    return null;
  }
}

export default {
  connectWallet,
  getDaiBalance,
};
